import { AfterViewInit, ChangeDetectorRef, ViewChild, Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Router, TitleStrategy } from '@angular/router';
import { BaseRequestService } from 'app/_services/base.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { reduce } from 'lodash';
import { DatePipe } from '@angular/common'
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/_services/common.services';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'l, LTS'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-tank-nozzle-sales',
  templateUrl: './tank-nozzle-sales.component.html',
  styleUrls: ['./tank-nozzle-sales.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class TankNozzleSalesComponent {
  foir: any = { 'title': "Tank & Nozzle Sales Comparison" };
  @ViewChild('picker1', { static: false }) public picker1;
  @ViewChild('picker2', { static: false }) public picker2;
  @Input() rocode: any
  printed: boolean = false
  header1: any = [
    {
      header: 'DU_PUMP_NOZZLE',
      column: 'nozzle_id',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'text',
    },
    {
      header: 'Current Totalizer',
      column: 'end_volume',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'text',
    },
    {
      header: 'Previous Totalizer',
      column: 'start_volume',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'text',
    },
    {
      header: 'NET NOZZLE SALES VOLUME',
      column: 'total_volume',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'text',
    },
    {
      header: 'NET NOZZLE SALES AMOUNT',
      column: 'total_amount',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'text',
    }
  ]
  header4 = [
    {
      header: 'PRODUCT',
      column: 'product_code',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'product',
    },
    {
      header: 'TANK OPENING STOCK(A)',
      column: 'start_product_volume',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'INVOICE RECEIPT VOLUME(B)',
      column: 'decant_volume',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'TOTAL(C=A+B)',
      column: 'total_tank',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'TANK CLOSING VOLUME(D)',
      column: 'end_product_volume',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'TOTAL SALE BASED ON TANK(E=C-D)',
      column: 'tank_sale',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'DIFFERENCE (NET NOZZLE SALE - NET TANK SALE(E))',
      column: 'difference',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    }
  ]
  header5 = [
    {
      header: 'PRODUCT',
      column: 'product_code',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'product',
    },
    {
      header: 'P1(0.75%/0.25%)',
      column: 'p1',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'P2(0.6%/0.2%)',
      column: 'p2',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'T(4% OF STOCK)',
      column: 't',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'PV',
      column: 'pv',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    }
  ]
  header3 = [
    {
      header: 'TANK',
      column: 'tank',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'text',
    },
    {
      header: 'PRODUCT',
      column: 'product_code',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'product',
    },
    {
      header: 'PRODUCT HEIGHT(mm.)',
      column: 'end_product_height',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'PRODUCT VOLUME(Ltr.)',
      column: 'total',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'WATER HEIGHT(mm.)',
      column: 'end_water_height',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'WATER VOLUME(Ltr.)',
      column: 'end_water_volume',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'NET VOLUME(Ltr.)',
      column: 'end_product_volume',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    }
  ]
  header2 = [
    {
      header: 'PRODUCT',
      column: 'product_code',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'product',
    },
    {
      header: 'NET NOZZLE SALE(M)',
      column: 'nozzle_sales_volume',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: '2T OIL(N)',
      column: 'oil',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'TESTING(O)',
      column: 'test_sales_volume',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'NET TOTALIZER SALE(M-(N+O)) Volume',
      column: 'net_totalizer',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'NET TOTALIZER SALE(M-(N+O)) Amount',
      column: 'net_totalizer_amount',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'NET TRANSACTION SALE(SALE-(N+O)) VOLUME',
      column: 'net_trans',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    },
    {
      header: 'NET TRANSACTION SALE(SALE-(N+O)) AMOUNT',
      column: 'net_trans_amount',
      filter: '',
      sort: true,
      visible: true,
      classc: 'text-center',
      classtab: 'truncate',
      type: 'number',
    }
  ]

  product = ['MS', "HS", 'X2']
  refreshro: any;
  startDate: string = ''
  endDate: string = ''

  constructor(private _changeDetectorRef: ChangeDetectorRef, public sanitizer: DomSanitizer,
    private _fuseConfirmationService: FuseConfirmationService, private _matDialog: MatDialog,
    public datepipe: DatePipe,
    private router: Router, private httpClient: HttpClient, public cs: CommonService,
    private baseService: BaseRequestService, private toast: MyToastrService) {

    this.refreshro = this.cs.refreshed.subscribe(change => {
      if (window.location.href.includes('sales')) {
        this.fetchRecord()
      }
    })
  }


  foirfun(data) {
    if (!this.printed) {
      console.log("Data", data.value)
      this.printed = true
    }

  }

  searchfoir() {

  }

  getproduct() {
    // product
    let q = { skip: 0, limit: 100 }
    this.baseService.doRequest(`/api/product_master`, 'get', null, q).subscribe((result: any) => {
      if (result && result.data && result.data.length > 0) {
        this.product = [];
        result.data.forEach(prod => {
          this.product.push(
            prod.product_code
          )
          // this.producthash[prod.product_code]= {
          //   id:prod._id,
          //   product_code:prod.product_code,
          //   product_name:prod.product_name
          // }
        })
        this.fetchRecord()
        // this.cs.producthash = this.producthash
        // this.product_code = this.product[0].product_code
      }
      // this.fetchRecord()
    })
  }

  ngOnDestroy(): void {
    if (this.refreshro) {
      this.refreshro.unsubscribe()
    }
  }

  downloadfoir() {
    var s = new Date()
    this.cs.triggerfoirdownload(s)
  }

  toggleAccordion(section) {
    const content = document.getElementById(`section${section}`);
    content.style.display = content.style.display === 'block' ? 'none' : 'block';
  }

  ngOnInit(): void {
    this.foir.start_date_moment = moment()
    this.foir.end_date_moment = moment()
    this.foir.start_time_moment = "00:00"
    this.foir.end_time_moment = "23:59"
    this.foir.title = `Tank & Nozzle Sales Comparison - ${this.rocode}`
    this.foir.titles = `FOIR - ${this.rocode}`
    this.getproduct()

    // this.foir.data = {
    //   "ro_code": "100010",
    //   "nozzles": [
    //     {
    //       "nozzle_id": "3_2_2",
    //       "start_amount": 0,
    //       "end_amount": 0,
    //       "start_volume": 0,
    //       "end_volume": 0,
    //       "product_code": "MS",
    //       "total_volume":0
    //     },
    //     {
    //       "nozzle_id": "3_2_1",
    //       "start_amount": 7605950,
    //       "end_amount": 7608045,
    //       "start_volume": 159499.83,
    //       "end_volume": 159538.23,
    //       "product_code": "HS",
    //       "total_volume": 38.40000000002328
    //     },
    //     {
    //       "nozzle_id": "3_1_2",
    //       "start_amount": 7605950,
    //       "end_amount": 7608045,
    //       "start_volume": 159499.83,
    //       "end_volume": 159538.23,
    //       "product_code": "HS",
    //       "total_volume": 38.40000000002328
    //     },
    //     {
    //       "nozzle_id": "3_1_1",
    //       "start_amount": 7605950,
    //       "end_amount": 7608045,
    //       "start_volume": 159499.83,
    //       "end_volume": 159538.23,
    //       "product_code": "MS",
    //       "total_volume": 38.40000000002328
    //     },
    //     {
    //       "nozzle_id": "2_2_2",
    //       "start_amount": 7605950,
    //       "end_amount": 7608045,
    //       "start_volume": 159499.83,
    //       "end_volume": 159538.23,
    //       "product_code": "MS",
    //       "total_volume": 38.40000000002328
    //     },
    //     {
    //       "nozzle_id": "2_2_1",
    //       "start_amount": 7605950,
    //       "end_amount": 7608045,
    //       "start_volume": 159499.83,
    //       "end_volume": 159538.23,
    //       "product_code": "HS",
    //       "total_volume": 38.40000000002328
    //     },
    //     {
    //       "nozzle_id": "2_1_2",
    //       "start_amount": 7605950,
    //       "end_amount": 7608045,
    //       "start_volume": 159499.83,
    //       "end_volume": 159538.23,
    //       "product_code": "HS",
    //       "total_volume": 38.40000000002328
    //     },
    //     {
    //       "nozzle_id": "2_1_1",
    //       "start_amount": 7605950,
    //       "end_amount": 7608045,
    //       "start_volume": 159499.83,
    //       "end_volume": 159538.23,
    //       "product_code": "MS",
    //       "total_volume": 38.40000000002328
    //     },
    //     {
    //       "nozzle_id": "1_2_2",
    //       "start_amount": 7605950,
    //       "end_amount": 7608045,
    //       "start_volume": 159499.83,
    //       "end_volume": 159538.23,
    //       "product_code": "MS",
    //       "total_volume": 38.40000000002328
    //     },
    //     {
    //       "nozzle_id": "1_2_1",
    //       "start_amount": 7605950,
    //       "end_amount": 7608045,
    //       "start_volume": 159499.83,
    //       "end_volume": 159538.23,
    //       "product_code": "HS",
    //       "total_volume": 38.40000000002328
    //     },
    //     {
    //       "nozzle_id": "1_1_2",
    //       "start_amount": 7605950,
    //       "end_amount": 7608045,
    //       "start_volume": 159499.83,
    //       "end_volume": 159538.23,
    //       "product_code": "HS",
    //       "total_volume": 38.40000000002328
    //     },
    //     {
    //       "nozzle_id": "1_1_1",
    //       "start_amount": 7605950,
    //       "end_amount": 7608045,
    //       "start_volume": 159499.83,
    //       "end_volume": 159538.23,
    //       "product_code": "MS",
    //       "total_volume": 38.40000000002328
    //     }
    //   ],
    //   "tanks": [
    //     {
    //       "tank": "2",
    //       "start_product_volume": 0,
    //       "start_water_volume": 0,
    //       "start_product_height": 0,
    //       "start_water_height": 0,
    //       "product_code": "HS",
    //       "end_product_volume": 0,
    //       "end_water_volume": 0,
    //       "end_water_height": 0,
    //       "end_product_height": 0,
    //       "decant_volume": 0,
    //       "total_volume":0,
    //     },
    //     {
    //       "tank": "1",
    //       "start_product_volume": 0,
    //       "start_water_volume": 0,
    //       "start_product_height": 0,
    //       "start_water_height": 0,
    //       "product_code": "MS",
    //       "total_volume":0,
    //       "end_water_height": 0,
    //       "end_product_volume": 0,
    //       "end_water_volume": 0,
    //       "end_product_height": 0,
    //       "decant_volume": 0
    //     }
    //   ],
    //   "product_sales": [
    //     {
    //       "product_code": "MS",
    //       "nozzle_sales_amount": 10477,
    //       "nozzle_sales_volume": 75,
    //       "test_sales_volume": 75,
    //       "test_sales_amount": 10477
    //     },
    //     {
    //       "product_code": "HS",
    //       "nozzle_sales_amount": 12572,
    //       "nozzle_sales_volume": 78,
    //       "test_sales_volume": 78,
    //       "test_sales_amount": 12572
    //     }
    //   ]
    // }


  }

  fetchRecord() {
    try {
      const starttime = this.foir.start_time_moment.split(":")
      const endtime = this.foir.end_time_moment.split(":")
      const startdate = new Date(new Date(this.foir.start_date_moment).setHours(starttime[0], starttime[1], 0)).toUTCString()
      const enddate = new Date(new Date(this.foir.end_date_moment).setHours(endtime[0], endtime[1], 0)).toUTCString()
      const startdatel = new Date(new Date(this.foir.start_date_moment).setHours(starttime[0], starttime[1], 0))
      const enddatel = new Date(new Date(this.foir.end_date_moment).setHours(endtime[0], endtime[1], 0))

      var request = {
        "ro_code": this.rocode,
        "start_date": this.datepipe.transform((startdate.replace(' GMT', '')), 'yyyy-MM-ddTHH:mm:ss'),
        "end_date": this.datepipe.transform((enddate.replace(' GMT', '')), 'yyyy-MM-ddTHH:mm:ss')
      }
      this.startDate = (this.datepipe.transform((startdatel), 'yyyy-MM-ddTHH:mm:ss')).replace('T', ' ')
      this.endDate = this.datepipe.transform((enddatel), 'yyyy-MM-ddTHH:mm:ss').replace('T', ' ')
      this.baseService.doRequest('/api/dutransaction/foir_reports', 'post', request).subscribe(data => {
        console.log("data response", data)
        if (data && data.ro_code) {
          this.foir.data = data
          this.processdata()
        }
      }, error => {
        console.log("error", error)
      })
    }
    catch {
      console.log("error")
    }

  }

  processdata() {
    try {
      console.log("foir.data.nozzles", this.foir)
      var temp = JSON.parse(JSON.stringify(this.foir.data.nozzles))
      const result = temp.reduce((acc: any, val: any) => {
        //search in acc array if there're an element with the same "date"
        const element = acc.find(x => x.product_code == val.product_code)
        if (!element) {     //if not exist
          acc.push(val)
        }  //add the val to acc
        else {
          element.total_volume += val.total_volume
          element.total_amount += val.total_amount
        }
        return acc;
      }, []);
      console.log("prod sales", result)
      this.foir.data.nozzle_Sales = []
      result.forEach(ele => {
        ele.nozzle_sales_amount = 0
        ele.nozzle_sales_volume = 0
        ele.test_sales_volume = 0
        ele.test_sales_amount = 0
        ele.oil = 0
        this.foir.data.product_sales.forEach(prod => {
          if (prod.product_code == ele.product_code) {
            // ele.nozzle_sales_amount = prod.nozzle_sales_amount
            ele.nozzle_sales_volume = ele.total_volume
            ele.nozzle_sales_amount = ele.total_amount
            ele.test_sales_volume = prod.test_sales_volume
            ele.test_sales_amount = prod.test_sales_amount
            ele.net_trans = prod.nozzle_sales_volume - (ele.test_sales_volume + ele.oil)
            ele.net_trans_amount = prod.nozzle_sales_amount - (ele.test_sales_amount + ele.oil)
            ele.net_totalizer = ele.total_volume - (ele.test_sales_volume + ele.oil)
            ele.net_totalizer_amount = ele.total_amount - (ele.test_sales_amount + ele.oil)
          }
        })
      })
      this.foir.data.nozzle_Sales = result

      var temps = JSON.parse(JSON.stringify(this.foir.data.tanks))
      this.foir.data.tanks.forEach(tt => {
        tt.total = tt.end_product_volume + tt.end_water_volume
      })
      const tank_result = temps.reduce((acc: any, val: any) => {
        //search in acc array if there're an element with the same "date"
        const element = acc.find(x => x.product_code == val.product_code)

        if (!element)       //if not exist
          acc.push(val)   //add the val to acc
        else {
          element.start_product_volume += val.start_product_volume  //simple increment the xUsed property
          element.end_product_volume += val.end_product_volume  //simple increment the xUsed property
          element.decant_volume += val.decant_volume  //simple increment the xUsed property
        }
        return acc;
      }, []);

      let days = Math.floor((new Date(this.foir.end_date_moment).getTime() - new Date(this.foir.start_date_moment).getTime()) / 1000 / 60 / 60 / 24);
      this.foir.days = days
      this.foir.x = days / 365 * 600000

      this.foir.pvc = [];
      tank_result.forEach(tan => {
        this.foir.data.nozzle_Sales.forEach(prod => {
          let pvcc: any = {}
          if (prod.product_code == tan.product_code) {
            tan.net_totalizer = prod.net_totalizer
            tan.total_tank = tan.start_product_volume + tan.decant_volume
            tan.tank_sale = tan.total_tank - tan.end_product_volume
            tan.difference = tan.net_totalizer - tan.tank_sale
            if (prod.product_code == 'HS') {
              pvcc.p1 = Math.min(this.foir.x, prod.nozzle_sales_volume) * 0.25 / 100
              if (prod.nozzle_sales_volume > this.foir.x) {
                pvcc.p2 = 0.2 / 100 * (prod.nozzle_sales_volume - this.foir.x)
              }
              else {
                pvcc.p2 = 0
              }
            }
            else {
              pvcc.p1 = Math.min(this.foir.x, prod.nozzle_sales_volume) * 0.75 / 100
              if (prod.nozzle_sales_volume > this.foir.x) {
                pvcc.p2 = 0.6 / 100 * (prod.nozzle_sales_volume - this.foir.x)
              }
              else {
                pvcc.p2 = 0
              }
            }
            pvcc.t = (4 * tan.end_product_volume) / 100
            if (tan.difference < 0) {
              pvcc.pv = pvcc.p1 + pvcc.p2 + pvcc.t
            }
            else {
              pvcc.pv = pvcc.t
            }
            pvcc.product_code = tan.product_code
            this.foir.pvc.push(pvcc)
          }
        })


      })
      this.foir.data.tank_Sales = tank_result
      if (this.foir.pvc && this.foir.pvc.length > 0 && this.foir.data.tank_Sales && this.foir.data.tank_Sales.length > 0) {
        var permisible_products = ''
        var not_permisible_products = ''
        this.foir.pvc.forEach(ele => {
          this.foir.data.tank_Sales.forEach(tank => {
            if (tank.product_code == ele.product_code && ele.pv > tank.difference) {
              if (permisible_products == "") {
                permisible_products += `${tank.product_code}`
              }
              else {
                permisible_products += `/${tank.product_code}`
              }
            }
            if (tank.product_code == ele.product_code && ele.pv < tank.difference) {
              // not_permisible_products += `${tank.product_code} `
              if (not_permisible_products == "") {
                not_permisible_products += `${tank.product_code}`
              }
              else {
                not_permisible_products += `/${tank.product_code}`
              }
            }
          })
        })
        this.foir.data.permisible_products = permisible_products
        this.foir.data.not_permisible_products = not_permisible_products
      }



      console.log("foir", this.foir)
      this._changeDetectorRef.markForCheck()
    }
    catch {
      console.log("Error2")
    }
  }
}
