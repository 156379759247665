import { Component } from '@angular/core';
import { PopupService } from './_services/popup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public popupService: PopupService) {
    // Check every minute if the popup should be shown
    setInterval(() => {
      if (this.popupService.shouldShowPopup()) {
        this.popupService.setPopupVisible(true);
      }
    }, 60000); // Check every minute (60000 milliseconds)
  }
}
