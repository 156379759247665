import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  private popupVisible: boolean = false;

  constructor() { }

  shouldShowPopup(): boolean {
    const currentTime = new Date();
    const lastPopupTime = localStorage.getItem('lastPopupTime');
    
    // Show popup every 5 minutes
    if (!lastPopupTime || (currentTime.getTime() - parseInt(lastPopupTime, 10)) >= 5 * 60 * 1000) {
      localStorage.setItem('lastPopupTime', currentTime.getTime().toString());
      return true;
    }
    return false;
  }

  setPopupVisible(visible: boolean): void {
    this.popupVisible = visible;
    if (visible) {
      setTimeout(() => {
        this.popupVisible = false;
      }, 60000); // Close popup after 1 minute (60000 milliseconds)
    }
  }

  isPopupVisible(): boolean {
    return this.popupVisible;
  }
}
