/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [


    // {
    //     id   : 'assets',
    //     title: 'Assets',
    //     type : 'basic',
    //     icon : 'heroicons_outline:collection',
    //     link : '/assets'
    // },
    // {
    //     id   : 'manage-qr',
    //     title: 'QR Codes',
    //     type : 'basic',
    //     icon : 'heroicons_outline:view-grid-add',
    //     link : '/manage-qr'
    // },
    {
        id: 'retail-outlets',
        title: 'Retail Outlet',
        type: 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon: 'heroicons_solid:office-building',
        link: '/retail-outlets'
    },
    //  {
    //     id   : 'sales',
    //     title: 'Summary',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/sales'
    // },
    // {
    //     id   : 'Summary',
    //     title: 'Summary',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'summarize',
    //     link : '/summary'
    // },
    {
        id: 'du-transactions',
        title: 'Transactions',
        type: 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon: 'double_arrow',
        link: '/du-transactions'
    },
    {
        id: 'foirreports',
        title: 'Sales',
        type: 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon: 'double_arrow',
        link: '/summary/sales'
    },
    {
        id: 'pricing',
        title: 'Pricing',
        type: 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon: 'price_check',
        link: '/pricing'
    },
    {
        id: 'shifts',
        title: 'Shifts',
        type: 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon: 'access_time',
        link: '/shifts'
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon: 'heroicons_outline:users',
        link: '/users'
    },
    // {
    //     id   : 'audit-log',
    //     title: 'Shift Log',
    //     type : 'basic',
    //     icon : 'sync',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     link : '/audit-log'
    // },
    {
        id: 'audit',
        title: 'Audit Log',
        type: 'basic',
        icon: 'sync',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        link: '/audit-log/audit'
    },
    {
        id: 'alert',
        title: 'Alerts',
        type: 'basic',
        icon: 'feather:alert-triangle',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        link: '/alerts'
    },
    {
        id: 'interlock',
        title: 'Interlock',
        type: 'basic',
        icon: 'heroicons_outline:lock-open',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        link: '/interlocks'
    },
    // {
    //     id   : 'reports',
    //     title: 'Reports',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'feather:file',
    //     link : '/reports'
    // },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon: 'feather:file',
        link: '/reports'
    },
    // {
    //     id   : 'status',
    //     title: 'Status',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'feather:file',
    //     link : '/status'
    // },

    // {
    //     id   : 'romap',
    //     title: 'RO Map',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'feather:file',
    //     link : '/reports/romap'
    // },
    // {
    //     id   : 'rfid',
    //     title: 'RFID',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'heroicons_outline:user-circle',
    //     link : '/rfid'
    // },
    {
        id: 'tickets',
        title: 'Tickets',
        type: 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon: 'heroicons_outline:ticket',
        link: '/downtime'
    },
    {
        id: 'settings',
        title: 'Settings',
        type: 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon: 'heroicons_outline:cog',
        link: '/settings'
    },
    {
        id: 'version',
        title: 'Version',
        type: 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon: 'heroicons_solid:information-circle',
        link: '/versionno'
    },
    {
        id: 'tankoffset',
        title: 'Tank Offset',
        type: 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon: 'heroicons_solid:presentation-chart-line',
        link: '/tankoffset'
    },
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation
/*export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];*/
