import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { CommonService } from 'app/_services/common.services';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseRequestService } from 'app/_services/base.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { default as _rollupMoment } from 'moment';
import { RouterModule, Routes } from '@angular/router';

import * as _moment from 'moment';
import { MatTabGroup } from '@angular/material/tabs';
import { keyBy } from 'lodash';
const moment = _rollupMoment || _moment;
import { FuseConfirmationService } from '@fuse/services/confirmation';


export const MY_FORMATS = {
  parse: {
    dateInput: 'l, LTS'
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'classy-layout',
  templateUrl: './classy.component.html',
  styleUrls: ['./classy.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
  refreshmap: any = { 0: "Manual", 60000: "1 Min", 300000: "5 Min" }
  // @ViewChildren('datepickers') datepickers: QueryList<any>;
  @ViewChild('dpi', { static: false }) public dpi;
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;  // Reference to MatTabGroup
  // datepickers: any[] = [];
  colfilterUpdate = new Subject<any>();
  isScreenSmall: boolean;
  navigation: Navigation;
  user: User;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  showadd: boolean = false;
  buttontext: string = ''
  currenttab: any = {};
  ro: any = {};
  public touchUi = false;
  public enableMeridian = true;
  public minDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  maxDate = new Date()

  form = this.fb.group({
    dash: ['RO'],
    search: ['']
  })

  formtickets =
  this.fb.group({
    dash: ['Open'],
  })
  formalerts =
    this.fb.group({
      dash: ['Open'],
    })
  formreports =
    this.fb.group({
      dash: ['Individual'],
    })
  columns: any = [];
  colfilter: any = {};
  refreshed = 0;
  client: any = ''
  /**
   * Constructor
   */
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  currenttickets: string='Open'
  currentalertss: string = 'Open'
  refreshtime: any = 0;
  inter: NodeJS.Timer;
  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    public api: BaseRequestService,
    public _fuseConfirmationService: FuseConfirmationService,
    private _navigationService: NavigationService,
    private _userService: UserService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService
  ) {
    this.commonService.addText.subscribe(values => {
      this.buttontext = values
    })
    this.commonService.addChange.subscribe(values => {
      this.showadd = values
    })
    this.commonService.refreshtime.subscribe(values => {
      this.refreshtime = values;
    })

    this.commonService.columns.subscribe(values => {
      this.columns = values
    })

    this.commonService.searchdata.subscribe(values => {
      this.colfilter = values
    })

    this.colfilterUpdate.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.commonService.searchfiler(this.colfilter)
      });

  }


  columnFilter(key, value, col) {
    if (col.showCalander) {
      this.colfilterUpdate.next(moment(key).format('YYYY-MM-DD HH:mm:ss'))
    }
    else {
      if (key !== null && key != "") {
        this.commonService.searchfiler(this.colfilter)
      }
      else {
        this.colfilterUpdate.next(key)
      }
    }
    //    this.commonService.searchfiler(this.colfilter)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  ngAfterViewInit() {
    // const datepickersArray = this.datepickers.toArray();
    // console.log(datepickersArray);
    // Additional logic for the initialized datepickers
    this.refreshperinterval()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */

  onToggleChange(event: Event) {
    // Your logic to handle the toggle change
    console.log('Toggle changed:', (event.target as HTMLInputElement).value);
    // Call your function here
    this.commonService.alertss((event.target as HTMLInputElement).value)
    this.currentalertss = (event.target as HTMLInputElement).value
  }

  onToggleChangetickets(event: any) {
    const value = event.value;
    console.log('Toggle changed:', value);
    this.commonService.tickets(value)
    this.currenttickets = value;

    // Call your service function
    // this.commonService.alertss(value);

    // Check if the value is "ALL" and switch to the desired tab
    if (value === 'All') {

      // Change 1 to the index of the tab you want
    }
  }
  ngOnInit(): void {
    // Subscribe to navigation data
    console.log("dash", this.formalerts)
    this.form.get('dash').valueChanges.subscribe(selectedValue => {
      this.commonService.dash(selectedValue)
    })
    this.formtickets.get('dash').valueChanges.subscribe(selectedValue => {
      this.commonService.tickets(selectedValue)
      this.currenttickets = selectedValue
    })
    this.formalerts.get('dash').valueChanges.subscribe(selectedValue => {
      this.commonService.alertss(selectedValue)
      this.currentalertss = selectedValue
    })
    this.form.get('dash').valueChanges.subscribe(selectedValue => {
      this.commonService.dash(selectedValue)
    })
    this.formreports.get('dash').valueChanges.subscribe(selectedValue => {
      this.commonService.alertss(selectedValue)
    })
    // this.datepickers = Array(3).fill(null);

    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to the user service
    this._userService.user$
      .pipe((takeUntil(this._unsubscribeAll)))
      .subscribe((user: User) => {
        this.user = user;
        this.user = JSON.parse(localStorage.getItem('user'))
        if (this.user && this.user.ro_data && this.user.ro_data.length > 0) {
          this.fetchro()
        }
      });
    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {

        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this.commonService.currenttab.subscribe(values => {
      this.currenttab = values;
      console.log('currenttab', this.currenttab);
    })
    if (window.location.href.includes('ioml')) {
      this.client = 'ioml'
    }
    else {
      this.client = 'iocl'
    }
    console.log('currenttab', this.currenttab);
  }

  // ngAfterViewInit() {
  //   this.datepickers.forEach((datepicker, index) => {
  //     console.log(`Datepicker ${index + 1}:`, datepicker);
  //     // Additional logic for each initialized datepicker
  //   });
  // }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  columnFilterrangelsr(val: any, col: any): void {
    var searchedColName = col;
    // console.log("value", this.start)
    var dateDoc: any = {};
    console.log("value", this.range.value)
    dateDoc.startDate = this.range.controls.start.value
    dateDoc.endDate = this.range.controls.end.value
    if (dateDoc.startDate !== '' && dateDoc.endDate !== '' && dateDoc.endDate !== null && dateDoc.startDate !== null) {
      const gtr = (dateDoc.startDate).format('DD-MMM-YYYY')
      const lsr = (dateDoc.endDate).format('DD-MMM-YYYY')
      const values = { "$gte": gtr, "$lte": lsr }
      dateDoc = { 'startDate': '', 'endDate': '' }

      this.colfilterUpdate.next({ value: values, col });
    }
  }


  clearDatetimePicker(index: number, column: any) {
    // const picker = this.datetimePickers[index];
    this.colfilter[column.column] = null
    this.commonService.searchfiler(this.colfilter)
    // this.colfilterUpdate.next(null)
    // picker.value = null; // Clear the selected date and time
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  add(type) {
    this.commonService.create({ 'create': this.showadd, 'button': this.buttontext, 'type': type })
  }

  search() {

  }

  refresh(time) {
    this.commonService.refreshtext(time)
    this.refreshtime = time
    console.log("refreshed")
    this.refreshed = this.refreshed + 1
    this.commonService.refresh(this.refreshed)
  }



  refreshperinterval() {
    // if(this.refreshtime != 0 && this.refreshtime != undefined){
    this.inter = setTimeout(() => {
      this.refreshperinterval()
      if (this.refreshtime != 0 && this.refreshtime != undefined) {
        this.refreshed = this.refreshed + 1
        this.commonService.refresh(this.refreshed)
      }
    }, this.refreshtime);
    // }
  }


  fetchro() {
    let q = { "ro_code": String(this.user.ro_data[0]) }
    this.api.doRequest(`/api/ro_onboard/pull_romaster`, 'post', q).subscribe((result: any) => {
      if (result && result.data && result.data.length > 0 && result.status == 'success') {
        this.ro = result.data[0]
      }
    })

  }
}
